import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class HowToReservesProvider extends HttpRequest {
  getLastItem () {
    this.setHeader(getAuthToken())
    return this.get('/cms/how-to-reserves')
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('/cms/how-to-reserves', payload)
  }
}

export default HowToReservesProvider
